import request from '../utils/request';
import { getTpDataSign } from '../utils/rsa.js';
import axios from 'axios';
import qs from 'qs';
const gatewayUrl = process.env.VUE_APP_GATEWAY_GADS2;

// 广告列表
export function accountPixel(params, that, id) {
  return request({
    // baseURL:'http://192.168.1.18:8088/api',
    url: '/ads/accountPixel',
    cancelToken: new axios.CancelToken(function executor(c) {
      // 设置 cancel token
      // console.log(c);
      if (that) {
        that.source = c;
      }
    }),
    headers: { thirdLoginUserId: id },
    method: 'get',
    params: {
      ...params,
    },
  });
}

// 像素统计信息
export function pixelStats(params) {
  return request({
    url: '/ads/pixelStats',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// 历史输入过的网址、地区等
export function historyInput(params) {
  return request({
    url: '/ads/historyInput',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// 检查推广网址
export function websiteUrlCheck(params, id) {
  return request({
    url: '/ads/websiteUrlCheck',
    method: 'get',
    headers: { thirdLoginUserId: id },
    params: {
      ...params,
    },
  });
}

// 获取广告创建常量
export function adConstsMap(params) {
  return request({
    url: '/ads/adConstsMap',
    method: 'get',
    params: {
      ...params,
    },
  });
}

// 获取保存的受众
export function savedContents(params, id) {
  return request({
    url: '/ads/savedContents',
    method: 'get',
    headers: { thirdLoginUserId: id },
    params: {
      ...params,
    },
  });
}

// 删除保存的受众
export function savedContentsDel(data, id) {
  return request({
    url: '/ads/savedContentsDel',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data',
      thirdLoginUserId: id,
    },
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}

// 获取受众类型下的受众
export function targetingSearch(params, thirdLoginUserId) {
  return request({
    baseURL: gatewayUrl,
    url: '/delivery/ads/targetingSearch',
    method: 'get',
    params: {
      ...params,
    },
    headers: {
      thirdLoginUserId,
    },
  });
}

// 获取广告账户下的主页
export function accountPages(params, id) {
  return request({
    url: '/ads/accountPages',
    method: 'get',
    timeout: 0,
    headers: { thirdLoginUserId: id },
    params: {
      ...params,
    },
  });
}

// 受众可视化
export function deliveryEstimateAndSentence(data, id) {
  return request({
    url: '/ads/deliveryEstimateAndSentence',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      thirdLoginUserId: id,
    },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 保存受众
export function saveSavedContent(data, id) {
  return request({
    url: '/ads/saveSavedContent',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      thirdLoginUserId: id,
    },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 上传文件
export function upload(data) {
  return request({
    url: '/media/upload',
    method: 'post',
    data: data,
    timeout: 0,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
// 上传文件到 FB
export function mediaUrlUploadFb(data) {
  return request({
    url: '/ads/mediaUrlUploadFb',
    method: 'post',
    data: data,
    timeout: 0,
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
// 检测 文件 是否 已经上传
export function mediaUrlUploadCheck(data) {
  return request({
    url: '/ads/mediaUrlUploadCheck',
    method: 'post',
    data: data,
    timeout: 0,
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}

// 上传视频封面图
export function uploadVideoThumb(data) {
  return request({
    url: '/media/uploadVideoThumb',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
// 通过网址上传文件
export function uploadUrl(data) {
  return request({
    url: '/media/uploadUrl',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

// 查主页下账户
export function pageInstagrams(params, id) {
  return request({
    url: '/ads/pageInstagrams',
    method: 'get',
    headers: { thirdLoginUserId: id },
    params: {
      ...params,
    },
  });
}

// 查账户下帖子
export function pagePosts(params, id) {
  return request({
    url: '/ads/pagePosts',
    method: 'get',
    headers: { thirdLoginUserId: id },
    params: {
      ...params,
    },
  });
}
// 通过 id 搜索 fb帖子
export function getPagePostById(params, id) {
  return request({
    url: '/ads/pagePostById',
    method: 'get',
    headers: { thirdLoginUserId: id },
    params: {
      ...params,
    },
  });
}
// 广告素材
export function search(data) {
  return request({
    url: '/media/search',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}

// 广告预览
export function preview(data) {
  return request({
    url: '/ads/preview',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 获取广告账户默认设置
export function getSetsByAccounts(data) {
  return request({
    url: '/account/getSetsByAccounts',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 命名规则字典项
export function getDicts(params) {
  return request({
    url: '/account/getDicts',
    method: 'get',
    params: {
      ...params,
    },
  });
}
// 保存广告偏好设置

export function setContent(data) {
  return request({
    url: '/account/set-content',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 获取广告账号列表
export function accounts(params) {
  return request({
    url: `/account/accounts/${params.id}`,
    method: 'get',
    params: {
      ...params,
    },
  });
}
// 拆分数量
export function splitNum(data) {
  return request({
    url: '/ads/splitNum',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 广告拆分预览
export function splitView(data) {
  return request({
    url: 'ads/splitView',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 广告创建
export function create(data) {
  return request({
    url: '/ads/create',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    timeout: 60000,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}

// 草稿箱
export function draftSearch(params) {
  return request({
    url: '/ads/draftSearch',
    method: 'get',
    params: {
      ...params,
    },
  });
}
// 删除草稿
export function draftDelete(data) {
  return request({
    url: '/ads/draftDelete',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
// 删除草稿
export function draftDel(data) {
  return request({
    url: '/ads/draftDel',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 上传草稿到faceBook
export function draftsUpload(params) {
  return request({
    url: '/ads/draftsUpload',
    method: 'get',
    params: {
      ...params,
    },
  });
}
// 草稿复制并创建
export function draftCopyAndUpload(params) {
  return request({
    url: '/ads/draftCopyAndUpload',
    method: 'get',
    params: {
      ...params,
    },
  });
}
// 获取广告详情
export function adForUpdate(data, id) {
  return request({
    url: '/ads/adForUpdate',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      thirdLoginUserId: id,
    },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 更新广告系列
export function updateDraftCampaign(data) {
  return request({
    url: '/ads/updateDraftCampaign',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 更新广告组
export function updateAdSet(data, id) {
  return request({
    url: '/ads/updateAdSet',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      thirdLoginUserId: id,
    },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 更新广告
export function updateAd(data, id) {
  return request({
    url: '/ads/updateAd',
    method: 'post',
    data: data,
    timeout: 60000,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      thirdLoginUserId: id,
    },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 创建幻灯
export function generateSlideshowVideo(data) {
  return request({
    url: '/ads/generateSlideshowVideo',
    method: 'post',
    data: data,
    timeout: 60000,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 获取幻灯创建进度
export function querySlideshowVideo(data, id) {
  return request({
    url: '/ads/querySlideshowVideo',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      thirdLoginUserId: id,
    },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 检测商品是否可用
export function promoteableSale(params) {
  return request({
    url: '/sale/promoteableSale',
    method: 'get',
    params: {
      ...params,
    },
    hideErrorMessage: true,
  });
}
// 搜索站点
export function searchSites(params) {
  return request({
    url: '/sale/searchSites',
    method: 'get',
    params: {
      ...params,
    },
  });
}
// 生成推广链接
export function addSaleToSite(data) {
  return request({
    url: '/sale/addSaleToSite',
    method: 'post',
    data: data,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 广告系列关联商品
export function saleCampaignRel(data, id) {
  return request({
    url: '/sale/saleCampaignRel',
    method: 'post',
    data,
    headers: { thirdLoginUserId: id },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}
// 手动关联商品
export function bindManul(data) {
  return request({
    url: `/saleBind/bindManul`,
    method: 'post',
    data: JSON.stringify(data),
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
// 上传视频到FB
export function mediaFileUploadFb(data, id) {
  return request({
    url: '/ads/mediaFileUploadFb',
    method: 'post',
    headers: { thirdLoginUserId: id },
    data,
    timeout: 0,
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}

// 重新上传视频到FB
export function mediaFileUploadFbCleanDate(data, id) {
  return request({
    url: '/ads/mediaFileUploadFbCleanDate',
    method: 'post',
    headers: { thirdLoginUserId: id },
    data,
    timeout: 0,
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}

// 绑定商品和像素
export function saleBindPixel(params) {
  return request({
    url: '/sale/saleBindPixel',
    method: 'get',
    params: {
      ...params,
    },
  });
}
// /sale/domainsByFbpixels 根据像素获取网域
export function domainsByFbpixels(params) {
  return request({
    url: '/sale/domainsByFbpixels',
    method: 'get',
    params,
  });
}
// 查询主页广告容量
export function pageVolumes(params, id) {
  return request({
    url: '/ads/pageVolumes',
    method: 'get',
    headers: { thirdLoginUserId: id },
    params,
  });
}
// 编辑主页的被封状态
export function editPageBlockStatus(params) {
  return request({
    url: '/ads/editPageBlockStatus',
    method: 'get',
    params,
  });
}

// 根据广告链接获取站点，商品像素信息
export function promotionInfo(params) {
  return request({
    url: '/sale/promotionInfo',
    method: 'get',
    params,
  });
}

// 获取广告缓存数据
export function getAdCacheData(data) {
  return request({
    url: '/ads/adCreateCatchGet',
    method: 'post',
    data,
    timeout: 0,
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
// 缓存广告数据
export function setAdCacheData(data) {
  return request({
    url: '/ads/adCreateCatchSet',
    method: 'post',
    data,
    timeout: 0,
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}

// 常用兴趣词查询
export function targetingCommonQuery(data) {
  return request({
    baseURL: gatewayUrl,
    url: '/delivery/ads/targetingCommonQuery',
    method: 'post',
    data,
    timeout: 0,
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
// 常用兴趣词保存
export function targetingCommonSave(data) {
  return request({
    url: '/ads/targetingCommonSave',
    method: 'post',
    data,
    timeout: 0,
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
// 常用兴趣词删除
export function targetingCommonDel(data) {
  return request({
    url: '/ads/targetingCommonDel',
    method: 'post',
    data,
    timeout: 0,
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
// 获取受益方

export function queryConfigAdBeneficiary(params) {
  return request({
    url: 'ads/queryConfigAdBeneficiary',
    method: 'get',
    params,
  });
}
// 批量获取视频上传进度
export function querySlideshowVideoBatch(data, id) {
  return request({
    url: '/ads/querySlideshowVideoBatch',
    method: 'post',
    data,
    timeout: 0,
    headers: { thirdLoginUserId: id },
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}

// 新的上传素材接口
export function newUpload(data, signData) {
  console.log(getTpDataSign(signData));
  return request({
    // baseURL: process.env.VUE_APP_NEWUPLOAD,
    baseURL: 'https://gads-autoupload.giikin.com:1001/api',
    url: '/ads/media/upload',
    method: 'post',
    data: data,
    timeout: 0,
    headers: {
      'Content-Type': 'multipart/form-data',
      ...getTpDataSign(signData),
    },
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}

// 素材保存接口
export function saveMedia(data) {
  return request({
    url: '/media/saveMedia',
    method: 'post',
    data,
    timeout: 0,
    transformRequest: [
      function (data) {
        return JSON.stringify(data);
      },
    ],
  });
}

// 新的创建幻灯接口
export function generateSlideshowVideoNew(data, signData) {
  return request({
    baseURL: process.env.VUE_APP_NEWUPLOAD,
    // baseURL: 'http://192.168.4.131:9000/api',
    url: '/ads/media/generateSlideshowVideo',
    method: 'post',
    data,
    timeout: 0,
    headers: {
      'Content-Type': 'multipart/form-data',
      ...getTpDataSign(signData),
    },
    transformRequest: [
      function (data) {
        return data;
      },
    ],
  });
}
